export function classes(
    ...classes: Array<string | Record<string, boolean>>
): string {
    return classes
        .map((item) =>
            typeof item === 'string' ? item : conditionalClasses(item)
        )
        .join(' ');
}

export function conditionalClasses(value: Record<string, boolean>): string {
    return Object.entries(value)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(' ');
}
