import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import patientsReducer from '@feature/patients.slice';
import patientReducer from '@feature/patient.slice';
import presentationsReducer from '@store/feature/presentations.slice';
import TrgCurrentMethodReducer from '@store/feature/trg__current-method.slice';
import trgListReducer from '@store/feature/trg.slice';
import trgStateReducer from '@store/feature/trg-state.slice';
import trgEditToolReducer from '@store/feature/tools.slice';
import triggerScalePoints from '@store/feature/trigger__scale-points.slice';
import pathReducer from '@store/feature/folder.slice';
import presentationIdSReducer from '@store/feature/presentationId.slice';
import collectionsReducer from '@store/feature/collections.slice';
import presentationToolsReducer from '@store/feature/presentationTools.slice';
import presentationEditFiguresReducer from '@store/feature/presentationEditFigures.slice';

export const store = configureStore({
    reducer: {
        patient: patientReducer,
        patients: patientsReducer,
        path: pathReducer,
        collections: collectionsReducer,
        presentations: presentationsReducer,
        presentationId: presentationIdSReducer,
        trg__current_method: TrgCurrentMethodReducer,
        trg__list: trgListReducer,
        trg__state: trgStateReducer,
        edit__tool: trgEditToolReducer,
        trigger__scale_points: triggerScalePoints,
        presentationTools: presentationToolsReducer,
        presentationEditFigures: presentationEditFiguresReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
