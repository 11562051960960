export function constructLocalDate(dateNumber: number): string {
    const date = new Date(dateNumber);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const stringDate = date.toLocaleDateString('ru-RU', options);
    return stringDate.slice(0, -2);
}
