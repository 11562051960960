import axios, { AxiosResponse } from 'axios';
import { AxiosInstance } from '@APIs/axiosInstance';
import { IPatient } from '@interfaces/patient.interface';
import { resizeFile } from './compress';
import { toast } from 'react-toastify';

export const getAllPatients = async (): Promise<{
    data: IPatient[];
    status: number;
} | void> => {
    try {
        const { data, status } = await AxiosInstance.get('/doctor/patients');
        return { data, status };
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                throw {
                    data: error.response.data,
                    status: error.response.status,
                };
            }
        }
    }
};

export const updatePatient = async (
    newData: IPatient
): Promise<{
    data: IPatient;
    status: number;
} | void> => {
    try {
        const patientId = newData.id;

        const { data, status } = await AxiosInstance.put(
            `/doctor/patient`,
            {
                name: newData.name,
                surname: newData.surname,
                middleName: newData.middleName,
                dateOfBirth: newData.dateOfBirth,
                sex: newData.sex,
                email: newData?.email,
                'patient-notify': newData['patient-notify'],
            },
            {
                params: {
                    patientId,
                },
            }
        );
        return { data, status };
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: 'top-right',
                });

                throw {
                    data: error.response.data,
                    status: error.response.status,
                };
            }
        }
    }
};

export const uploadPatientAvatar = async (
    patientId: string,
    file: globalThis.File
): Promise<AxiosResponse<{ message: string; url: string }>> => {
    const newFile = await resizeFile(file);
    const formData = new FormData();

    formData.append('photo', newFile, file.name);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
        params: {
            patientId,
        },
        timeout: 0,
    };
    return await AxiosInstance.post(`/patient/avatar`, formData, config);
};
