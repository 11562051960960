import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICollectionPreview } from '@interfaces/collection.interface';
import { PhotoCollectionApis } from '@APIs/photo-collection.apis';
import { RootState } from '@store/index';

export interface ICollections {
    value: ICollectionPreview[];
    status: 'successfully' | 'loading' | 'failed' | 'initialStatus';
}

const initialState: ICollections = {
    value: [],
    status: 'initialStatus',
};

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        addCollection: (state, action: PayloadAction<ICollectionPreview>) => {
            state.value.push(action.payload);
        },
        removeCollection: (state, action: PayloadAction<string>) => {
            state.value = state.value.filter(
                (collection) => collection.id !== action.payload
            );
        },
        setCollections: (
            state,
            action: PayloadAction<ICollectionPreview[]>
        ) => {
            state.value = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncLoadCollections.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncLoadCollections.fulfilled, (state, action) => {
                state.status = 'successfully';
                state.value = action.payload;
            })
            .addCase(asyncLoadCollections.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const asyncLoadCollections = createAsyncThunk(
    'collections/fetchCollections',
    async (patientId: string) => {
        const res = await PhotoCollectionApis.getAll(patientId);
        return res.data;
    }
);

export const selectCollections = (state: RootState): ICollectionPreview[] =>
    state.collections.value;

export const selectCollectionsLoadStatus = (
    state: RootState
): 'initialStatus' | 'loading' | 'failed' | 'successfully' =>
    state.collections.status;

export const { addCollection, removeCollection, setCollections } =
    collectionsSlice.actions;
export default collectionsSlice.reducer;
