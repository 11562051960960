import { ICollection } from '@interfaces/collection.interface';
import { ICollectionStatus } from '@store/feature/patient.slice';

const oldTypes = [
    // Все типы старых коллекций
    'Внутриротовые фотографии',
    'Лицевые фотографии',
    'Данные КЛКТ',
    'Сагиттальные срезы зубов',
];

export function createStatus(
    data: ICollection[],
    type: string
): ICollectionStatus[] {
    const statuses: ICollectionStatus[] = [];
    if (type !== 'former') {
        data.map((el) => {
            statuses.push({
                name: el.title,
                id: el.id,
                status: el.status ? el.status : null,
            });
        });
    } else {
        // Для старых коллекций берем статусы самых "новых" коллекций каждого типа
        const sortedData = data.sort((a, b) => {
            return b.created_at - a.created_at;
        });
        oldTypes.map((name) => {
            const latestCollection = sortedData.find(
                (val) => val.title === name
            );
            if (latestCollection) {
                statuses.push({
                    name: name,
                    id: latestCollection.id,
                    status: latestCollection.status
                        ? latestCollection.status
                        : null,
                });
            } else {
                statuses.push({
                    name: name,
                    id: null,
                    status: null,
                });
            }
        });
    }
    return statuses;
}
