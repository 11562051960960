import { IPatient } from '@interfaces/patient.interface';

export class ImageCache {
    private cache: Record<string, HTMLImageElement> = {};
    private static instance: ImageCache | null = null;
    private counter = 0;

    constructor(private limit: number = 100) {
        if (!ImageCache.instance) {
            ImageCache.instance = this;
        }
        return ImageCache.instance;
    }

    async load(url: string): Promise<HTMLImageElement> {
        if (!this.cache[url]) {
            this.cache[url] = await this.createImage(url);
            this.counter++;
            this.checkLimit();
        }
        return this.cache[url];
    }

    private createImage(url: string) {
        return new Promise((resolve: (value: HTMLImageElement) => void) => {
            const image = new Image();
            image.addEventListener('load', () => {
                resolve(image);
            });
            image.crossOrigin = '*';
            image.src = url;
        });
    }

    checkLimit(): void {
        if (this.counter > this.limit) {
            const entires = Object.entries(this.cache);
            entires.length = Math.floor(this.limit / 2);
            this.cache = Object.fromEntries(entires);
        }
    }
}

export const convertCurrencyToString = (value: number | string) => {
    const currencyFormatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
    });

    return currencyFormatter.format(Number(value)).replace('₽', '');
};

export function ageToStr(age: number) {
    const count = age % 100;
    let txt = 'лет';

    if (count >= 5 && count <= 20) {
        return age + ' ' + txt;
    }

    const lastDigit = count % 10;
    if (lastDigit === 1) {
        txt = 'год';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        txt = 'года';
    }

    return age + ' ' + txt;
}

export function id() {
    return String.fromCharCode(
        Math.random() * 46 + 48,
        Math.random() * 46 + 48,
        Math.random() * 46 + 48,
        Math.random() * 46 + 48,
        Math.random() * 46 + 48,
        Math.random() * 46 + 48
    );
}

export const getPatientName = (
    patient:
        | Pick<IPatient, 'id' | 'name' | 'surname' | 'middleName'>
        | undefined
) => {
    if (!patient) {
        return 'Неизвестный пациент';
    }

    const { name, surname, middleName } = patient;
    return `${surname} ${name} ${middleName}`;
};

export const findPerpendiculars = (
    src: { x: number; y: number } | undefined,
    dst: { x: number; y: number } | undefined
) => {
    if (!src || !dst) {
        return [];
    }

    const { x: x1, y: y1 } = src;
    const { x: x2, y: y2 } = dst;
    const endLen = 26; // length of end lines

    // Calculate vector perpendicular to the line
    const px = y1 - y2;
    const py = x2 - x1;
    const length = Math.hypot(px, py);

    // Normalize and scale to desired length
    const scale = endLen / length;
    const scaledPx = px * scale;
    const scaledPy = py * scale;

    return [
        [x1, y1, x2, y2],
        [x1 + scaledPx, y1 + scaledPy, x1 - scaledPx, y1 - scaledPy],
        [x2 + scaledPx, y2 + scaledPy, x2 - scaledPx, y2 - scaledPy],
    ];
};
