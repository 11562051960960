import axios, { CancelTokenSource } from 'axios';
import { AxiosInstance } from '@APIs/axiosInstance';

export const uploadPhoto = async (
    patient_id: string,
    collection_id: string,
    file: globalThis.File,
    onUpdate: (progressEvent: ProgressEvent<FileReader>) => void
): Promise<void> => {
    try {
        const formData = new FormData();
        const newFileName = encodeURIComponent(file.name);

        formData.append('image', file, newFileName);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Disposition':
                    "attachment;filename*=UTF-8''" + newFileName,
            },
            params: {
                patient_id,
                collection_id,
            },
            onUploadProgress: onUpdate,
            timeout: 0,
        };

        await AxiosInstance.post(`/collections/image`, formData, config);
    } catch (error) {
        throw error;
    }
};

export const listPhoto = async (
    patientId: string,
    collectionId: string
): Promise<{ data: unknown }> => {
    try {
        const { status, data } = await AxiosInstance.get(
            `/photo/list/${patientId}/${collectionId}`
        );

        if (status === 200 || status === 204 || status === 304) return { data };
        throw new Error(data.message);
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message);
        } else {
            throw new Error((error as Error).message);
        }
    }
};

export const uploadPhototoFolder = async (
    patientId: string,
    folderId: string,
    file: globalThis.File,
    source: CancelTokenSource,
    onUpdate: (progressEvent: ProgressEvent<FileReader>) => void
): Promise<{ status: number }> => {
    try {
        // Create an object of formData
        const formData = new FormData();
        const newFileName = encodeURIComponent(file.name);

        formData.append('image', file, newFileName);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Disposition':
                    "attachment;filename*=UTF-8''" + newFileName,
            },
            params: {
                folder_id: folderId,
                patient_id: patientId,
            },
            onUploadProgress: onUpdate,
            timeout: 0,
            cancelToken: source.token,
        };

        const { status, data } = await AxiosInstance.post(
            `/store/files/image`,
            formData,
            config
        );
        if (status === 200 || status === 204 || status === 304)
            return { status };
        throw new Error(data.message);
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message);
        } else {
            throw new Error((error as Error).message);
        }
    }
};
