import { StorePresentation } from '@components/store/PresentationStorePage';
import axios from 'axios';
import { AxiosInstance } from '@APIs/axiosInstance';
import { IPage, IPresentation } from '@interfaces/presentation.interface';

// TODO: раскидать данный файл в апи и интерфейс
export const getPresentationTemplates = async (): Promise<{
    data: StorePresentation[];
}> => {
    try {
        const { status, data } = await AxiosInstance.get(`/presentation/store`);

        if (status === 200 || status === 204 || status === 304) {
            return { data };
        }
        throw new Error(data.message);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message);
        } else {
            throw new Error((error as Error).message);
        }
    }
};

export const createPresentation = async (
    templateId: string,
    patientId: string
): Promise<{
    data: IPresentation;
}> => {
    try {
        const { status, data } = await AxiosInstance.post<IPresentation>(
            `/presentation/create`,
            {
                presentationID: templateId,
                patientId,
            }
        );
        if (status === 200 || status === 204 || status === 304) {
            return { data };
        }
        throw new Error((data as unknown as { message: string }).message);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message);
        } else {
            throw new Error((error as Error).message);
        }
    }
};

interface IFont {
    fontFamily: string;
    fontWeight: number;
    id: string;
    ttfUrl: string;
}

export interface Presentation {
    name: string;
    state: string;
    pages: IPage[];
    date: number;
    patientId?: string;
    importFonts: IFont[];
}

export const getPresentation = async (
    presentationId: string
): Promise<{
    data: Presentation;
}> => {
    return await AxiosInstance.get(`/presentation/full`, {
        params: {
            id: presentationId,
        },
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setPresentationState = async (
    presentationId: string,
    state: string
) => {
    await AxiosInstance.post(`/presentation/state`, {
        presentationId,
        state,
    })
        .then(() => {
            return true;
        })
        .catch((err) => {
            throw err;
        });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function updatePageImage(
    id: string,
    prop: {
        value: string;
        rotate: number;
        scale: number;
        transform: { x: number; y: number };
        reflection?: { x: number; y: number };
    }
) {
    return await AxiosInstance.put(
        '/presentation/page/image/editing',
        { ...prop },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            params: { id },
        }
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function updatePageText(
    id: string,
    prop: {
        editing?: {
            value: string;
            rotate: number;
            scale: number;
            transform: { x: number; y: number };
        };
        text?: string;
    }
) {
    return await AxiosInstance.put(
        '/presentation/page/text',
        { ...prop },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            params: { id },
        }
    );
}
