const code = ` onmessage = function (e) {
    const url = e.data;
    fetch(url)
        .then((res) => res.blob())
        .then((value) => {
            postMessage(URL.createObjectURL(value));
        });
};
`;

const blob = new Blob([code], { type: 'application/javascript' });
const worker_script = URL.createObjectURL(blob);

let loader: Worker;

export function loadWorker(): Worker {
    if (!loader) {
        loader = new Worker(worker_script);
    }

    return loader;
}
