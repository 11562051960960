import React, { useCallback, useEffect, useState } from 'react';
import './TrgTable.scss';

import { SearchComponent } from '@components/common/search-component/search-component';
import { DataGrid, GridFooter, ruRU } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core';
import { TrgTableColDefs } from './TrgTableColldefs';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectTrgList,
    selectTrgListIsRestricted,
    selectTrgListState,
} from '@store/feature/trg.slice';
import { ITrg } from '@interfaces/trg.interface';
import LoadingIndicator from '@components/patient/patientinfo/LoadingIndicator';
import TableSubscriptionAlert from '@components/tableSubscriptionAlert/TableSubscriptionAlert';
import { classes } from '@utils/classNames';
import { TrgFake } from '@constants/tablesData';

const rowHover = '&.MuiDataGrid-root .MuiDataGrid-row:hover';

const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            overflow: 'inherit',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
        [rowHover]: {
            backgroundColor: '#b2b2b214',
        },
    },
});

const TrgState: { [key: string]: string } = {
    IN_PROCESS: 'В работу',
    COMPLETED: 'Опубликовать',
};

export const TrgPage = (): JSX.Element => {
    const trg_list = useAppSelector(selectTrgList);
    const isLoading = useAppSelector(selectTrgListState);
    const isRestricted = useAppSelector(selectTrgListIsRestricted);
    const [trg, setTrg] = useState<ITrg[]>([]);
    const [filtered_trg, setFilteredTrg] = useState<ITrg[]>([]);

    useEffect(() => {
        setTrg(trg_list);
        setFilteredTrg(trg_list);
    }, [trg_list]);

    function sortByDateComparator(first_trg: ITrg, second_trg: ITrg) {
        return second_trg.created_at - first_trg.created_at;
    }

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setFilteredTrg(
                e.target.value && e.target.value.trim()
                    ? trg.filter(
                          ({ patient, state, title }) =>
                              patient &&
                              `${patient.surname} ${patient.name} ${title} ${TrgState[state]}`
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                      )
                    : trg
            );
        },
        [trg]
    );

    const classesTable = useStyles();
    return (
        <div className="trg__container">
            <div className="trg__title-container">
                <p className="trg__title">Все расчеты</p>
                <SearchComponent onChange={onChange} />
            </div>
            <LoadingIndicator isLoading={false} />
            <div className="trg__table-container">
                <DataGrid
                    className={classes(classesTable.root, {
                        ['DataGridCustom']: isRestricted,
                    })}
                    components={{
                        Footer: () => {
                            return (
                                <div>
                                    {isRestricted && (
                                        <TableSubscriptionAlert type={'trg'} />
                                    )}
                                    <GridFooter />
                                </div>
                            );
                        },
                    }}
                    columns={TrgTableColDefs()}
                    rows={[...filtered_trg]
                        .sort(sortByDateComparator)
                        .concat(isRestricted ? TrgFake : [])}
                    disableColumnMenu={true}
                    loading={isLoading}
                    hideFooterSelectedRowCount={true}
                    disableSelectionOnClick={true}
                    getRowId={(row) => row.id}
                    localeText={
                        ruRU.components.MuiDataGrid.defaultProps.localeText
                    }
                />
            </div>
        </div>
    );
};

export default TrgPage;
