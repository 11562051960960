import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPatient } from '@interfaces/patient.interface';
import { RootState } from '..';

export interface ISelectedPatient {
    value: IPatient | null;
    status: ICollectionStatus[];
    isStatusSet: boolean;
}

export interface ICollectionStatus {
    name: string;
    status: 'completed' | 'waiting_for_filled' | 'in_process' | null;
    id: string | null;
}

const initial_state: ISelectedPatient = {
    value: null,
    status: [],
    isStatusSet: false,
};

interface UpdateCollectionStatusPayload {
    id: string;
    newStatus: 'completed' | 'waiting_for_filled' | 'in_process' | null;
}

export const patient_slice = createSlice({
    name: 'patients',
    initialState: initial_state,
    reducers: {
        setSelectedPatient(state, action: PayloadAction<IPatient | null>) {
            state.value = action.payload;
        },
        setPatientCollectionStatus(
            state,
            action: PayloadAction<ICollectionStatus[]>
        ) {
            state.status = action.payload;
            state.isStatusSet = true;
        },
        updateCollectionStatusById(
            state,
            action: PayloadAction<UpdateCollectionStatusPayload>
        ) {
            const { id, newStatus } = action.payload;
            const collectionToUpdate = state.status.find(
                (collection) => collection.id === id
            );
            if (collectionToUpdate) {
                collectionToUpdate.status = newStatus;
            }
        },
    },
});

export const {
    setSelectedPatient,
    setPatientCollectionStatus,
    updateCollectionStatusById,
} = patient_slice.actions;
export const selectSelectedPatient = (state: RootState): IPatient | null =>
    state.patient.value;
export const selectSelectedPatientStatus = (
    state: RootState
): ICollectionStatus[] => state.patient.status;
export const getSelectedPatientStatus = (state: RootState): boolean =>
    state.patient.isStatusSet;

export default patient_slice.reducer;
