import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPresentation } from '@interfaces/presentation.interface';
import { RootState } from '@store/index';
import { presentationAPIs } from '@APIs/presentation.apis';

export interface IPresentationList {
    value: IPresentation[];
    status: 'successfully' | 'loading' | 'failed';
    isRestricted: boolean;
}

const initialState: IPresentationList = {
    value: [],
    status: 'successfully',
    isRestricted: false,
};

export const presentationsSlice = createSlice({
    name: 'presentations',
    initialState,
    reducers: {
        pushPresentationsItem(state, action: PayloadAction<IPresentation>) {
            state.value.push(action.payload);
        },
        changePresentationStatus(
            state,
            action: PayloadAction<{ id: string; newState: string }>
        ) {
            const { id, newState } = action.payload;
            const foundPresentation = state.value.find(
                (presentation) => presentation.id === id
            );
            if (foundPresentation) {
                foundPresentation.state = newState;
            }
        },
        updatePresentationTitle(
            state,
            action: PayloadAction<{ id: string; name: string }>
        ) {
            const foundPresentation = state.value.find(
                (presentation) => presentation.id === action.payload.id
            );
            if (foundPresentation) {
                foundPresentation.name = action.payload.name;
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(asyncLoadPresentations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncLoadPresentations.fulfilled, (state, action) => {
                state.status = 'successfully';
                state.value = action.payload.presentations;
                state.isRestricted = action.payload.is_restricted;
            })
            .addCase(asyncLoadPresentations.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const asyncLoadPresentations = createAsyncThunk(
    'presentations/fetchPresentations',
    async () => {
        const res = await presentationAPIs.getPresentations();
        return res.data;
    }
);

export const {
    pushPresentationsItem,
    changePresentationStatus,
    updatePresentationTitle,
} = presentationsSlice.actions;
export const selectPresentations = (state: RootState): IPresentation[] =>
    state.presentations.value;
export const selectPresentationsStatus = (state: RootState): boolean =>
    state.presentations.status !== 'successfully';
export const selectPresentationsIsRestricted = (state: RootState): boolean =>
    state.presentations.isRestricted;
export default presentationsSlice.reducer;
