import React, { useCallback, useEffect, useState } from 'react';
import './TrgEdit.scss';
import { useHistory, useParams } from 'react-router';
import { PointList } from '@components/TrgEdit/point-list/point-list';
import TrgEditToolBar from '@components/TrgEdit/toolbar/toolbar';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectCurrentTrgMethod,
    setCurrentTrgMethod,
} from '@feature/trg__current-method.slice';
import { ITrg } from '@interfaces/trg.interface';
import { TrgAPIs } from '@APIs/trg.apis';
import { ROUTES } from '@constants/routes';
import { toast } from 'react-toastify';
import {
    selectTrgEditTool,
    setTrgEditTool,
    setTrgEnabledEditTool,
} from '@feature/tools.slice';
import { TrgToolEnum } from '@interfaces/tools.interface';
import { Alert } from '@components/common/alert/Alert';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import ai from '@images/ai.svg';
import EditTools from '@components/TrgEdit/edit-tools/TrgEditTools';
import TrgMethods from '@components/TrgEdit/methods/methods';
import axios from 'axios';
import CustomToastError from '@components/UI/CustomToastError/CustomToastError';
import { getPatientName } from '@utils/utils';
import {
    ITrgEditPoint,
    ITrgMethod,
    ITrgMethodPoint,
} from '@interfaces/trg-method.interface';
import TrgEditDisplay from '@components/TrgEdit/TrgEditDisplay/TrgEditDisplay';

export enum TRGState {
    LOADING,
    SETMEASURE,
    CHOOSEMETHOD,
    SETPOINT,
    DONE,
}

export function TrgEdit(): JSX.Element {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { trgId } = useParams() as unknown as { trgId: string };

    const [currentState, setCurrentState] = useState<TRGState>(
        TRGState.LOADING
    );
    const [trg, setTrg] = useState<ITrg | undefined>();
    const [points, setPoints] = useState<ITrgEditPoint[]>([]);
    const [ruler, setRuler] = useState<{
        [key: string]: { x: number; y: number };
    } | null>(null);
    const [selectedPointName, setSelectedPointName] = useState<string | null>(
        null
    );
    const [methods, setMethods] = useState<ITrgMethod[]>([]);
    const [intervalId, setIntervalId] = useState<number>();
    const [scale, setScale] = useState({
        pointScale: 1,
        fontScale: 1,
    });
    const [isMethodsModalOpen, setIsMethodsModalOpen] = useState(false);

    const editTool = useAppSelector(selectTrgEditTool);
    const currentMethod = useAppSelector(selectCurrentTrgMethod);

    const mapPoints = useCallback(
        (
            trgPoints: {
                [key: string]: {
                    x: number;
                    y: number;
                    is_active: boolean;
                    description: string;
                    image: string;
                };
            },
            methodPoints: ITrgMethodPoint[]
        ): ITrgEditPoint[] => {
            return methodPoints.map((point) => {
                const trgPoint = trgPoints[point.name];
                return {
                    ...point,
                    x: trgPoint?.x ?? -1,
                    y: trgPoint?.y ?? -1,
                    is_active: trgPoint !== undefined,
                };
            });
        },
        []
    );

    // Установить ТРГ метод
    const setTrgMethod = (methodId: string) => {
        TrgAPIs.setTrgMethod(trgId, methodId)
            .then(() => {
                const foundMethod = methods.find(
                    (method) => method.id === methodId
                );
                if (foundMethod) {
                    dispatch(setCurrentTrgMethod(foundMethod));
                    const trgPoints = trg?.points ?? {};
                    const points = mapPoints(trgPoints, foundMethod.points);
                    setPoints(points);

                    const inactivePoint = points.find(
                        (point) => !point.is_active
                    );
                    if (inactivePoint) {
                        setSelectedPointName(inactivePoint.name);
                    } else {
                        setSelectedPointName(null);
                    }
                }
            })
            .catch(() => toast.error('Не удалось установить метод'));
    };

    // Запрос на получение ТРГ при загрузке страницы
    useEffect(() => {
        (async () => {
            try {
                const [trgResponse, methodsResponse] = await Promise.all([
                    TrgAPIs.getTrg(trgId),
                    TrgAPIs.getMethods(),
                ]);

                const trgData = trgResponse.data;
                const methodsData = methodsResponse.data;

                setRuler(trgData.ruler);
                setTrg(trgData);
                setMethods(methodsData);

                const foundMethodId = trgData.recently_method?.id;

                if (foundMethodId) {
                    const currentMethod = methodsData.find(
                        (method) => method.id === foundMethodId
                    );

                    if (currentMethod) {
                        dispatch(setCurrentTrgMethod(currentMethod));
                        const trgPoints = trgData.points ?? {};
                        const points = mapPoints(
                            trgPoints,
                            currentMethod.points
                        );
                        setPoints(points);

                        const inactivePoint = points.find(
                            (point) => !point.is_active
                        );
                        if (inactivePoint) {
                            setSelectedPointName(inactivePoint.name);
                        }
                    }
                }
            } catch (error) {
                if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 403
                ) {
                    toast.error('У Вас нет доступа к данному пациенту');
                    history.replace(ROUTES.TRG.END);
                }
            }
        })();
    }, []);

    // Динамическая установка этапа заполнения ТРГ
    useEffect(() => {
        if (points.length !== 0 && points.every((point) => point.is_active)) {
            setCurrentState(TRGState.DONE);
        } else if (currentMethod) {
            setCurrentState(TRGState.SETPOINT);
        } else if (Object.keys(ruler ?? {}).length === 2) {
            setCurrentState(TRGState.CHOOSEMETHOD);
            setIsMethodsModalOpen(true);
        } else {
            setCurrentState(TRGState.SETMEASURE);
        }
    }, [points, currentMethod, ruler]);

    // Динамическая установка инструментов редактирования
    useEffect(() => {
        if (trg) {
            if (currentState == TRGState.SETMEASURE) {
                dispatch(setTrgEditTool(TrgToolEnum.MEASURE));
                dispatch(
                    setTrgEnabledEditTool([
                        TrgToolEnum.MEASURE,
                        TrgToolEnum.MOVE,
                        TrgToolEnum.EDIT,
                        TrgToolEnum.ZOOM,
                        TrgToolEnum.OUT,
                    ])
                );
            } else if (
                [TRGState.SETPOINT, TRGState.DONE].includes(currentState)
            ) {
                dispatch(setTrgEditTool(TrgToolEnum.POINT));
                dispatch(
                    setTrgEnabledEditTool([
                        TrgToolEnum.POINT,
                        TrgToolEnum.MEASURE,
                        TrgToolEnum.MOVE,
                        TrgToolEnum.EDIT,
                        TrgToolEnum.ZOOM,
                        TrgToolEnum.OUT,
                    ])
                );
            }
        }
    }, [currentState, trg, currentMethod]);

    // Выбор точки в списке
    const onPointSelect = useCallback(
        (pointName: string) => {
            if (currentState == TRGState.SETPOINT) {
                setSelectedPointName(pointName);
            }
        },
        [currentState]
    );

    // удаление точки из ТРГ
    const disconnectPoint = (key: string) => {
        TrgAPIs.removeTrgPoint(trgId, key)
            .then((res) => {
                setTrg((prevState) => {
                    if (prevState)
                        return { ...prevState, points: res.data.points };
                });
                setSelectedPointName(key);
                const trgPoints = res.data.points ?? {};
                if (currentMethod) {
                    const points = mapPoints(trgPoints, currentMethod.points);
                    setPoints(points);
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
            });
    };

    // Обновление координат точки ТРГ
    const updateTrgPoint = useCallback(
        (key: string, x: number, y: number) => {
            TrgAPIs.updateTrgPoint(trgId, key, { x, y })
                .then((res) => {
                    setTrg((prevState) => {
                        if (prevState)
                            return { ...prevState, points: res.data.points };
                    });
                    setPoints((prevPoints) =>
                        prevPoints.map((point) =>
                            point.name === key ? { ...point, x, y } : point
                        )
                    );
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.message);
                    }
                });
        },
        [currentMethod]
    );

    // Обновление линейки
    const updateRulerPoint = useCallback(
        (key: 'm1' | 'm0', x: number, y: number) => {
            TrgAPIs.insertTrgRulerPoint(trgId, key, x, y).then((res) => {
                setRuler(res.data);
            });
        },
        []
    );

    // Обработка клика по изображению
    const onImageClick = (pos: { x: number; y: number }) => {
        switch (currentState) {
            case TRGState.SETMEASURE:
                setMeasure(pos);
                break;
            case TRGState.CHOOSEMETHOD:
                toast.info('Пожалуйста, выберите метод');
                break;
            case TRGState.SETPOINT:
                if (selectedPointName) {
                    insertPoint(selectedPointName, pos.x, pos.y);
                }
                break;
            case TRGState.DONE:
                toast.info('Точки расставлены, откройте Отчет');
                break;
            default:
                break;
        }
    };

    // Установка линейки
    const setMeasure = (pos: { x: number; y: number }) => {
        if (!ruler) {
            setRuler({ m0: { x: pos.x, y: pos.y } });
        } else {
            setRuler((prevState) => ({
                ...prevState,
                m1: { x: pos.x, y: pos.y },
            }));
        }
        TrgAPIs.insertTrgRulerPoint(
            trgId,
            ruler?.m0 ? 'm1' : 'm0',
            pos.x,
            pos.y
        )
            .then((res) => {
                setRuler(res.data);
            })
            .catch(() => {
                setRuler(null);
                toast.error('Не удалось установить линейку');
            });
    };

    // вставка точки в ТРГ
    const insertPoint = (key: string, x: number, y: number) => {
        TrgAPIs.insertTrgPoint(trgId, key, { x, y })
            .then((res) => {
                setTrg((prevState) => {
                    if (prevState)
                        return { ...prevState, points: res.data.points };
                });

                const trgPoints = res.data.points ?? {};
                if (currentMethod) {
                    const points = mapPoints(trgPoints, currentMethod.points);
                    setPoints(points);

                    const inactivePoint = points.find(
                        (point) => !point.is_active
                    );
                    if (inactivePoint) {
                        setSelectedPointName(inactivePoint.name);
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
            });
    };

    // Открытие Отчета
    const onReport = useCallback(() => {
        switch (currentState) {
            case TRGState.DONE:
                if (trg && currentMethod) {
                    console.log(currentMethod);
                    history.push(ROUTES.TRG.$('0').REPORT.END, {
                        trg_id: trg.id,
                        method_id: currentMethod.id,
                    });
                }
                break;
            case TRGState.SETMEASURE:
                toast.warn(
                    'Пожалуйста, установите точки m0 и m1 так, чтобы между ними было 10 мм'
                );
                break;
            case TRGState.CHOOSEMETHOD:
                toast.warn('Пожалуйста выберите метод');
                break;
            default:
                break;
        }
    }, [currentState, currentMethod]);

    // AI-разметка
    const setPointsAutomatically = useCallback(async () => {
        if (currentState === TRGState.SETMEASURE) {
            toast.info('Сначала установите 10 мм при помощи линейки.');
            return;
        }

        if (currentState === TRGState.CHOOSEMETHOD) {
            const firstMethodId = methods[0]?.id;
            if (firstMethodId) {
                setTrgMethod(firstMethodId);
                setIsMethodsModalOpen(false);
            }
        }

        if (intervalId) {
            return;
        }

        setIntervalId(-1);

        try {
            const traceResponse = await TrgAPIs.trace(trgId);
            const startTime = Date.now();

            const checkStatusInterval = setInterval(async () => {
                try {
                    const currentTime = Date.now();
                    if (currentTime - startTime >= 60000) {
                        clearInterval(checkStatusInterval);
                        toast.error(
                            'Наш сервер перегружен. Ваше AI-действие поставлено в очередь. Попробуйте вернуться на эту страницу позже.'
                        );
                        setIntervalId(undefined);
                        return;
                    }

                    const statusResponse = await TrgAPIs.checkTraceStatus(
                        traceResponse.data.id
                    );

                    if (
                        statusResponse.status === 200 &&
                        statusResponse.data.state === '2'
                    ) {
                        clearInterval(checkStatusInterval);

                        const res = await TrgAPIs.getTrg(trgId);
                        const trgData = res.data;
                        setTrg(trgData);

                        const trgPoints = trgData.points ?? {};
                        const points = mapPoints(
                            trgPoints,
                            currentMethod?.points ?? methods[0].points
                        );
                        setPoints(points);
                        setIntervalId(undefined);

                        const inactivePoint = points.find(
                            (point) => !point.is_active
                        );
                        if (inactivePoint) {
                            setSelectedPointName(inactivePoint.name);
                        }
                    }
                } catch (statusError) {
                    clearInterval(checkStatusInterval);
                    setSelectedPointName(null);
                    setIntervalId(undefined);
                }
            }, 5000);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 405) {
                toast.error(
                    <CustomToastError
                        text={
                            'Достигнут лимит по ТРГ в месяц, приобретите подписку'
                        }
                        buttonText={'Тарифы'}
                        onClick={() => history.push(ROUTES.PROFILE.END)}
                    />
                );
            } else if (
                axios.isAxiosError(error) &&
                error.code === 'ECONNABORTED'
            ) {
                toast.error('Превышено время ожидания ответа от сервера');
            }
            setSelectedPointName(null);
            setIntervalId(undefined);
        }
    }, [currentState, currentMethod, intervalId, methods, trgId, trg]);

    return (
        <div className="add-trg__container">
            {currentState == TRGState.LOADING ? (
                <CustomLoader />
            ) : (
                <>
                    <div className="add-trg__header">
                        <div
                            onClick={() => history.goBack()}
                            className="user-info__header-back"
                        />
                        <p className="user-info__title">
                            {getPatientName(trg?.patient)}
                        </p>
                        <TrgEditToolBar
                            onReport={onReport}
                            canOpenReport={currentState === TRGState.DONE}
                        />
                    </div>
                    <Alert
                        title="Редактор ТРГ"
                        description="В данном редакторе вы можете провести расчет бокового ТРГ. С помощью инструмента 'линейка' укажите расстояние в 10мм, выберите метод из выпадающего списка и поставьте точки с помощью инструмента 'карандаш'."
                        id="trg_edit"
                    />
                    <div className="add-trg__body">
                        <div className="add-trg__toolbar">
                            <div style={{ display: 'flex' }}>
                                <TrgMethods
                                    methods={methods}
                                    isActive={
                                        currentState !== TRGState.SETMEASURE
                                    }
                                    selectedMethodTitle={currentMethod?.title}
                                    setTrgMethod={setTrgMethod}
                                    isOpen={isMethodsModalOpen}
                                    setIsOpen={setIsMethodsModalOpen}
                                />
                                <EditTools className="add-trg__tools" />
                            </div>
                            <div style={{ display: 'flex', gap: 12 }}>
                                <div
                                    onClick={setPointsAutomatically}
                                    className={
                                        !(intervalId != undefined)
                                            ? 'add-trg__report-btnContainer__button'
                                            : 'add-trg__report-btnContainer__button-active'
                                    }
                                >
                                    {intervalId != undefined ? (
                                        <CustomLoader color="white" size={18} />
                                    ) : (
                                        <img
                                            width={25}
                                            height={25}
                                            src={ai}
                                            alt={'ai icon'}
                                        />
                                    )}
                                    <p>Разметить</p>
                                </div>
                            </div>
                        </div>
                        <div className="add-trg__grid-wrapper">
                            <div className="add-list-wrapper">
                                {!methods && <CustomLoader />}
                                <PointList
                                    points={points ?? []}
                                    selectedPointName={selectedPointName}
                                    onPointSelect={onPointSelect}
                                    onPointDisconnect={disconnectPoint}
                                />
                            </div>
                            {trg && (
                                <TrgEditDisplay
                                    currentState={currentState}
                                    trg={trg}
                                    points={points}
                                    ruler={ruler}
                                    editTool={editTool ?? TrgToolEnum.MOVE}
                                    scale={scale}
                                    onImageClick={onImageClick}
                                    setScale={setScale}
                                    updateTrgPoint={updateTrgPoint}
                                    updateRulerPoint={updateRulerPoint}
                                    isAILoading={intervalId !== undefined}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default TrgEdit;
