import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface ITriggerScalePoints {
    value: boolean;
}

const initial_state: ITriggerScalePoints = {
    value: false,
};

export const triggerScalePointsSlice = createSlice({
    name: 'trigger__scale-points',
    initialState: initial_state,
    reducers: {
        toggleTriggerState(
            state: ITriggerScalePoints,
            action: PayloadAction<boolean>
        ) {
            state.value = action.payload;
        },
    },
});

export const { toggleTriggerState } = triggerScalePointsSlice.actions;

export const selectTriggerScalePoints = (state: RootState): boolean =>
    state.trigger__scale_points.value;

export default triggerScalePointsSlice.reducer;
