import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

const initialState = {
    value: null,
};

export const presentationIdSlice = createSlice({
    name: 'presentationId',
    initialState: initialState,
    reducers: {
        setPresentationId: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setPresentationId } = presentationIdSlice.actions;
export const getPresentationId = (state: RootState): null | string =>
    state.presentationId.value;
export default presentationIdSlice.reducer;
