import { ROUTES } from '@constants/routes';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { TableAvatarCellElem } from '@components/common/table-component/table__avatar-cell';
import { TablePatientBtn } from '@components/common/table-component/table__patient-btn';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { TrgAPIs } from '@APIs/trg.apis';
import { constructLocalDate } from '@utils/constructLocalDate';
import { clearTrgImageParams } from '@feature/trg-state.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import TableEditInput from '@components/common/tableEditInput/TableEditInput';
import { updateTrgTitle } from '@feature/trg.slice';
import TrgTableActionsColumn from '@components/ClientTrgTable/TrgTableActionsColumn';

export function TrgTableColDefs(): GridColDef[] {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(clearTrgImageParams());
    };

    return [
        {
            field: 'title',
            headerName: 'Расчеты',
            disableColumnMenu: true,
            flex: 1,
            renderCell: function Patient(
                params: GridRenderCellParams
            ): JSX.Element {
                const title = params.row?.title;

                return (
                    <TableEditInput
                        value={title}
                        onAcceptIconClick={(title) => {
                            const trg = {
                                ...params.row,
                                title,
                                changed_at: Date.now(),
                            };

                            TrgAPIs.update(trg, trg.id)
                                .then(() => {
                                    dispatch(
                                        updateTrgTitle({
                                            id: trg.id,
                                            title: trg.title,
                                            updated_at: trg.changed_at,
                                        })
                                    );
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        toast.error(
                                            error.response.data.message
                                        );
                                    }
                                });
                        }}
                    />
                );
            },
        },
        {
            field: 'patient',
            headerName: 'Пациент',
            disableColumnMenu: true,
            flex: 2,
            renderCell: function Patient(
                params: GridRenderCellParams
            ): JSX.Element {
                const patientID = params.row?.patient?.id;

                return (
                    <TablePatientBtn
                        onClick={() => {
                            if (patientID) {
                                history.push(
                                    ROUTES.PATIENTS.$(patientID).TRG.END
                                );
                            } else {
                                toast.warn(
                                    'ТРГ без пациента. Перейдите к пациенту, чтобы сделать индивидуальное ТРГ.'
                                );
                            }
                        }}
                    >
                        <>
                            {patientID
                                ? TableAvatarCellElem({
                                      id: params.row.patient.id,
                                      avatar: params.row.patient.avatar,
                                      name: params.row.patient.name,
                                  })
                                : TableAvatarCellElem({
                                      id: undefined,
                                      avatar: undefined,
                                      name: 'Неизвестный',
                                  })}

                            <div className="table-row__patient-title">
                                {patientID
                                    ? params.row.patient.surname +
                                      ' ' +
                                      params.row.patient.name
                                    : 'Неизвестный'}
                            </div>
                        </>
                    </TablePatientBtn>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Дата создания',
            type: 'dateTime',
            width: 160,
            disableColumnMenu: true,
            align: 'left',
            renderCell: (params) => constructLocalDate(params.row.created_at),
            valueGetter: (params) => params.row.created_at,
            sortComparator: (a, b) => b - a,
        },
        {
            field: 'change',
            headerName: 'Действия',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => TrgTableActionsColumn({ params, onClick }),
        },
    ];
}
