import React, { useEffect } from 'react';
import { IFont } from '@interfaces/presentation.interface';

const FontLoader: React.FC<{ fonts: IFont[] }> = ({ fonts }) => {
    useEffect(() => {
        const promises = fonts.map((font) => {
            const fontFace = new FontFace(
                font.fontFamily,
                `url(${font.ttfUrl})`,
                {
                    weight: font.fontWeight.toString(),
                }
            );

            return fontFace.load();
        });

        Promise.all(promises)
            .then((loadedFontFaces) => {
                loadedFontFaces.forEach((loadedFontFace) => {
                    document.fonts.add(loadedFontFace);
                });
            })
            .catch((error) => {
                console.error('Failed to load fonts:', error);
            });
    }, [fonts]);

    return null;
};

export default FontLoader;
