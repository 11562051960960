import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { IPatient } from '@interfaces/patient.interface';
import { doctorAPIs } from '@APIs/doctor.apis';
import { RootState } from '..';

export interface IPatientList {
    value: IPatient[];
    is_restricted: boolean;
    status: 'successfully' | 'loading' | 'failed';
}

const initialState: IPatientList = {
    value: [],
    is_restricted: false,
    status: 'loading',
};

export const asyncLoadPatients = createAsyncThunk(
    'patients/fetchPatients',
    async () => {
        const res = await doctorAPIs.getPatients();
        return res.data;
    }
);

export const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        pushPatient(state, action: PayloadAction<IPatient>) {
            state.value.push(action.payload);
        },
        updatePatientsItem(
            state,
            action: PayloadAction<{ id: string; data: IPatient }>
        ) {
            state.value = state.value.map((patient) => {
                if (patient.id === action.payload?.id) {
                    return action.payload.data;
                } else {
                    return patient;
                }
            });
        },
        deletePatient(state, action: PayloadAction<{ id: string }>) {
            const index = state.value
                .map(function (el) {
                    return el.id;
                })
                .indexOf(action.payload.id);
            if (index !== -1) {
                state.value = [
                    ...state.value.slice(0, index),
                    ...state.value.slice(index + 1),
                ];
            } else {
                state.value = [...state.value];
            }
        },
        addAvatarUrl(
            state,
            action: PayloadAction<{ id: string; url: string }>
        ) {
            state.value = state.value.map((patient) => {
                if (patient.id === action.payload.id) {
                    return { ...patient, avatar: action.payload.url };
                } else {
                    return patient;
                }
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(asyncLoadPatients.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncLoadPatients.fulfilled, (state, action) => {
                state.status = 'successfully';
                state.value = action.payload.patients;
                state.is_restricted = action.payload.is_restricted;
            })
            .addCase(asyncLoadPatients.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { pushPatient, updatePatientsItem, deletePatient, addAvatarUrl } =
    patientsSlice.actions;
export const selectPatients = (state: RootState): IPatient[] =>
    state.patients.value;

export const selectPatientsState = (state: RootState): boolean =>
    state.patients.status !== 'successfully';

export const selectPatientsIsRestricted = (state: RootState): boolean =>
    state.patients.is_restricted;

export const selectPatient = createSelector(
    [
        (state: RootState): IPatient[] => state.patients.value,
        (state: RootState, id: string): string => id,
    ],
    (patients, id): IPatient | undefined => patients.find((p) => p.id === id)
);

export default patientsSlice.reducer;
