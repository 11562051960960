import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import {
    IArrow,
    ILine,
    IRectangle,
    PresentationFiguresEnum,
} from '@interfaces/presentationEditing.interface';

interface FiguresState {
    curves: ILine[];
    rectangles: IRectangle[];
    arrows: IArrow[];
    lines: ILine[];
    dotted_lines: ILine[];
    selectedFigureId: { id: string; type: PresentationFiguresEnum } | null;
}

const initialState: FiguresState = {
    curves: [],
    rectangles: [],
    arrows: [],
    lines: [],
    dotted_lines: [],
    selectedFigureId: null,
};

export const figuresSlice = createSlice({
    name: 'figures',
    initialState,
    reducers: {
        setShapes: (
            state,
            action: PayloadAction<{
                curves: ILine[];
                rectangles: IRectangle[];
                arrows: IArrow[];
                lines: ILine[];
                dotted_lines: ILine[];
            }>
        ) => {
            state.arrows = action.payload.arrows;
            state.curves = action.payload.curves;
            state.rectangles = action.payload.rectangles;
            state.lines = action.payload.lines;
            state.dotted_lines = action.payload.dotted_lines;
        },
        addLineFigure: (state, action: PayloadAction<ILine>) => {
            state.curves.push(action.payload);
        },
        addRectangle: (state, action: PayloadAction<IRectangle>) => {
            state.rectangles.push(action.payload);
        },
        updateRectangle: (state, action: PayloadAction<IRectangle>) => {
            const index = state.rectangles.findIndex(
                (rectangle) => rectangle.id === action.payload.id
            );
            if (index !== -1) {
                state.rectangles[index] = action.payload;
            }
        },
        addArrow: (state, action: PayloadAction<IArrow>) => {
            state.arrows.push(action.payload);
        },
        updateArrow: (state, action: PayloadAction<IArrow>) => {
            const index = state.arrows.findIndex(
                (arrow) => arrow.id === action.payload.id
            );
            if (index !== -1) {
                state.arrows[index] = action.payload;
            }
        },
        addLine: (state, action: PayloadAction<ILine>) => {
            state.lines.push(action.payload);
        },
        updateLine: (state, action: PayloadAction<ILine>) => {
            const index = state.lines.findIndex(
                (line) => line.id === action.payload.id
            );
            if (index !== -1) {
                state.lines[index] = action.payload;
            }
        },
        addDottedLine: (state, action: PayloadAction<ILine>) => {
            state.dotted_lines.push(action.payload);
        },

        updateDottedLine: (state, action: PayloadAction<ILine>) => {
            const index = state.dotted_lines.findIndex(
                (line) => line.id === action.payload.id
            );
            if (index !== -1) {
                state.dotted_lines[index] = action.payload;
            }
        },
        changeFigureColor: (
            state,
            action: PayloadAction<{
                id: string;
                type: PresentationFiguresEnum;
                color: string;
            }>
        ) => {
            switch (action.payload.type) {
                case PresentationFiguresEnum.CURVE:
                    const curve = state.curves.find(
                        (curve) => curve.id === action.payload.id
                    );
                    if (curve) {
                        curve.color = action.payload.color;
                    }
                    break;
                case PresentationFiguresEnum.RECTANGLE:
                    const rectangle = state.rectangles.find(
                        (rect) => rect.id === action.payload.id
                    );
                    if (rectangle) {
                        rectangle.stroke = action.payload.color;
                    }
                    break;
                case PresentationFiguresEnum.ARROW:
                    const arrow = state.arrows.find(
                        (arrow) => arrow.id === action.payload.id
                    );
                    if (arrow) {
                        arrow.color = action.payload.color;
                    }
                    break;
                case PresentationFiguresEnum.LINE:
                    const line = state.lines.find(
                        (line) => line.id === action.payload.id
                    );
                    if (line) {
                        line.color = action.payload.color;
                    }
                    break;
                case PresentationFiguresEnum.DOTTEDLINE:
                    const dotted_line = state.dotted_lines.find(
                        (dotted_line) => dotted_line.id === action.payload.id
                    );
                    if (dotted_line) {
                        dotted_line.color = action.payload.color;
                    }
                    break;
                default:
                    break;
            }
        },
        setSelectedFigure: (
            state: FiguresState,
            action: PayloadAction<{
                id: string;
                type: PresentationFiguresEnum;
            } | null>
        ) => {
            state.selectedFigureId = action.payload;
        },

        deleteFigure: (
            state: FiguresState,
            action: PayloadAction<{ id: string; type: PresentationFiguresEnum }>
        ) => {
            const { id, type } = action.payload;
            switch (type) {
                case PresentationFiguresEnum.CURVE:
                    state.curves = state.curves.filter(
                        (curve) => curve.id !== id
                    );
                    break;
                case PresentationFiguresEnum.RECTANGLE:
                    state.rectangles = state.rectangles.filter(
                        (rectangle) => rectangle.id !== id
                    );
                    break;
                case PresentationFiguresEnum.ARROW:
                    state.arrows = state.arrows.filter(
                        (arrow) => arrow.id !== id
                    );
                    break;
                case PresentationFiguresEnum.LINE:
                    state.lines = state.lines.filter((line) => line.id !== id);
                    break;
                case PresentationFiguresEnum.DOTTEDLINE:
                    state.dotted_lines = state.dotted_lines.filter(
                        (dotted_line) => dotted_line.id !== id
                    );
                    break;
                default:
                    break;
            }
            if (state.selectedFigureId?.id === id) {
                state.selectedFigureId = null;
            }
        },
    },
});

export const {
    addLineFigure,
    addRectangle,
    updateRectangle,
    addArrow,
    updateArrow,
    addLine,
    updateLine,
    addDottedLine,
    updateDottedLine,
    setSelectedFigure,
    deleteFigure,
    changeFigureColor,
    setShapes,
} = figuresSlice.actions;

export const selectCurves = (state: RootState): ILine[] =>
    state.presentationEditFigures.curves;
export const selectSelectedFigure = (
    state: RootState
): {
    id: string;
    type: PresentationFiguresEnum;
} | null => state.presentationEditFigures.selectedFigureId;
export const selectRectangles = (state: RootState): IRectangle[] =>
    state.presentationEditFigures.rectangles;
export const selectArrows = (state: RootState): IArrow[] =>
    state.presentationEditFigures.arrows;
export const selectLines = (state: RootState): ILine[] =>
    state.presentationEditFigures.lines;
export const selectDottedLines = (state: RootState): ILine[] =>
    state.presentationEditFigures.dotted_lines;

export default figuresSlice.reducer;
