import { TrgAPIs } from '@APIs/trg.apis';
import { ITrg } from '@interfaces/trg.interface';

import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '..';

interface ITrgList {
    value: ITrg[];
    status: 'successfully' | 'loading' | 'failed';
    isRestricted: boolean;
}

const initialState: ITrgList = {
    value: [],
    status: 'successfully',
    isRestricted: false,
};

export const asyncLoadTrgList = createAsyncThunk(
    'trg-list/fetchTrgList',
    async () => {
        const res = await TrgAPIs.getTrgs();
        return res.data;
    }
);

const TrgSlice = createSlice({
    name: 'trg-list',
    initialState,
    reducers: {
        deleteTrg(state, action: PayloadAction<string>) {
            state.value = state.value.filter(
                (trg) => trg.id !== action.payload
            );
        },
        updateTrgRatio(
            state,
            action: PayloadAction<Pick<ITrg, 'ratio' | 'id'>>
        ) {
            state.value = state.value.map((trg) => {
                if (trg.id === action.payload.id) {
                    return { ...trg, ratio: action.payload.ratio };
                }
                return trg;
            });
        },
        replaceTrg(state, action: PayloadAction<ITrg>) {
            state.value = state.value.map((trg) =>
                trg.id === action.payload.id ? action.payload : trg
            );
        },
        changeTrgStatus(
            state,
            action: PayloadAction<{
                id: string;
                newState: 'IN_PROCESS' | 'COMPLETED';
            }>
        ) {
            const { id, newState } = action.payload;
            state.value = state.value.map((trg) => {
                if (trg.id === id) {
                    return { ...trg, state: newState };
                }
                return trg;
            });
        },
        updateTrgTitle(
            state,
            action: PayloadAction<{
                id: string;
                title: string;
                updated_at: number;
            }>
        ) {
            const { id, title, updated_at } = action.payload;
            state.value = state.value.map((trg) => {
                if (trg.id === id) {
                    return { ...trg, title, updated_at };
                }
                return trg;
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(asyncLoadTrgList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncLoadTrgList.fulfilled, (state, action) => {
                state.status = 'successfully';
                state.value = action.payload.trgs;
                state.isRestricted = action.payload.is_restricted;
            })
            .addCase(asyncLoadTrgList.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const selectTrgList = (state: RootState): ITrg[] =>
    state.trg__list.value;

export const selectTrgListState = (state: RootState): boolean =>
    state.trg__list.status !== 'successfully';

export const selectTrg = createSelector(
    [selectTrgList, (state, id: string) => id],
    (trg_list, id) => trg_list.find((trg) => trg.id === id)
);
export const selectTrgListIsRestricted = (state: RootState): boolean =>
    state.trg__list.isRestricted;

export const {
    deleteTrg,
    replaceTrg,
    updateTrgRatio,
    changeTrgStatus,
    updateTrgTitle,
} = TrgSlice.actions;

export default TrgSlice.reducer;
