import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import { IArchiveCollection } from '@interfaces/collection.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface IPathPart {
    title: string;
    url: string;
}

export interface IFolderPath {
    path: IPathPart[];
    elements: IArchiveCollection[];
}

export async function getPath(
    folderId: string,
    patientId: string,
    path: IPathPart[]
): Promise<IPathPart[]> {
    return PhotoArchiveApis.getFolder(folderId).then((res) => {
        path.push({
            title: res.data.title,
            url: folderId,
        });
        if (res.data.parent) {
            return getPath(res.data.parent, patientId, path);
        } else {
            return path;
        }
    });
}

const initialState: IFolderPath = {
    path: [{ title: 'Хранилище', url: '../storage' }],
    elements: [],
};

export const pathSlice = createSlice({
    name: 'folder-path',
    initialState,
    reducers: {
        setCompletePath(state, action: PayloadAction<IPathPart[]>) {
            state.path = [...action.payload];
        },
        pushFolderToPath(state, action: PayloadAction<IPathPart>) {
            state.path.push(action.payload);
        },
        popFromPath(state, action: PayloadAction<void>) {
            const path = [...state.path];
            path.pop();
            state.path = path;
        },
        removePartsFromPath(state, action: PayloadAction<number>) {
            if (action.payload > 0)
                state.path = state.path.slice(0, action.payload + 1);
        },
        clearPath(state) {
            state.path = [{ title: 'Хранилище', url: '../storage' }];
        },
        replcaeElement(state, action: PayloadAction<IArchiveCollection>) {
            state.elements = state.elements.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );
        },
        setFolderElements(state, action: PayloadAction<IArchiveCollection[]>) {
            state.elements = [...action.payload];
        },
        removeFolderElement(state, action: PayloadAction<{ id: string }>) {
            const index = state.elements
                .map(function (el) {
                    return el.id;
                })
                .indexOf(action.payload.id);
            if (index !== -1) {
                state.elements = [
                    ...state.elements.slice(0, index),
                    ...state.elements.slice(index + 1),
                ];
            } else {
                state.elements = [...state.elements];
            }
        },
        clearFolder(state) {
            state.elements = [];
        },
    },
});

export const {
    setCompletePath,
    pushFolderToPath,
    popFromPath,
    clearPath,
    removePartsFromPath,
    setFolderElements,
    removeFolderElement,
    clearFolder,
    replcaeElement,
} = pathSlice.actions;
export const selectPath = (state: RootState): IFolderPath => state.path;

export default pathSlice.reducer;
