import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface ITrgState {
    brightness: number;
    contrast: number;
    isInvert: boolean;
}

const initialState: ITrgState = {
    brightness: 0,
    contrast: 0,
    isInvert: false,
};

const TrgStateSlice = createSlice({
    name: 'trg__state',
    initialState,
    reducers: {
        setTrgImageBrightness(state: ITrgState, action: PayloadAction<number>) {
            state.brightness = action.payload;
        },
        setTrgImageContrast(state: ITrgState, action: PayloadAction<number>) {
            state.contrast = action.payload;
        },
        setTrgImageIsInvert(state: ITrgState, action: PayloadAction<boolean>) {
            state.isInvert = action.payload;
        },
        clearTrgImageParams(state: ITrgState) {
            state.brightness = 0;
            state.contrast = 0;
            state.isInvert = false;
        },
    },
});

export const getBrightness = (state: RootState): number =>
    state.trg__state.brightness;
export const getContrast = (state: RootState): number =>
    state.trg__state.contrast;
export const getIsInverted = (state: RootState): boolean =>
    state.trg__state.isInvert;

export const {
    setTrgImageBrightness,
    setTrgImageContrast,
    setTrgImageIsInvert,
    clearTrgImageParams,
} = TrgStateSlice.actions;

export default TrgStateSlice.reducer;
