import { ITrgMethod } from '@interfaces/trg-method.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

const initialState: {
    value: ITrgMethod | null;
} = {
    value: null,
};

const TrgMethodsSlice = createSlice({
    name: 'trg__current-method',
    initialState,
    reducers: {
        setCurrentTrgMethod(state, action: PayloadAction<ITrgMethod>) {
            state.value = action.payload;
        },

        resetCurrentState(state) {
            state.value = null;
        },
    },
});

export const selectCurrentTrgMethod = (state: RootState): ITrgMethod | null =>
    state.trg__current_method.value;

export const { resetCurrentState, setCurrentTrgMethod } =
    TrgMethodsSlice.actions;

export default TrgMethodsSlice.reducer;
