import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PresentationToolsEnum } from '@interfaces/presentationEditing.interface';
import { RootState } from '@store/index';

export interface IPresentationTools {
    selectedTool: PresentationToolsEnum;
    color: string;
}

const initialState: IPresentationTools = {
    selectedTool: PresentationToolsEnum.CURSOR,
    color: '#000000',
};

export const presentationToolsSlice = createSlice({
    name: 'presentationTools',
    initialState: initialState,
    reducers: {
        setPresentationSelectedTool(
            state: IPresentationTools,
            action: PayloadAction<PresentationToolsEnum>
        ) {
            state.selectedTool = action.payload;
        },
        setPresentationToolColor(
            state: IPresentationTools,
            action: PayloadAction<string>
        ) {
            state.color = action.payload;
        },
    },
});

export const { setPresentationSelectedTool, setPresentationToolColor } =
    presentationToolsSlice.actions;

export const selectSelectedPresentationTool = (
    state: RootState
): PresentationToolsEnum => state.presentationTools.selectedTool;
export const selectPresentationToolColor = (state: RootState): string =>
    state.presentationTools.color;
export default presentationToolsSlice.reducer;
