import Resizer from 'react-image-file-resizer';

export const resizeFile = (file: File): Promise<Blob> =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            999999, // width
            999999, // height
            'JPEG', // compress format WEBP, JPEG, PNG
            10, // quality
            0, // rotation
            (uri) => {
                resolve(uri as Blob);
            },
            'blob'
        );
    });
