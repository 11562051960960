import React, { useEffect, useState, useCallback } from 'react';
import { StorePresentation } from '@components/store/PresentationStorePage';
import { useHistory, useParams } from 'react-router';
import style from './PresentationStoreFromCollectionPage.module.scss';
import { getPresentationTemplates } from '@utils/presentation';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import { TemplatePreview } from '@components/store/TemplatePreview/TemplatePreview';
import { presentationAPIs } from '@APIs/presentation.apis';
import { photoCollectionAPIs } from '@APIs/photo-collection.apis';
import {
    IAnnouncements,
    IConnectedToCollectionPresentation,
} from '@interfaces/presentation.interface';
import { ROUTES } from '@constants/routes';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { asyncLoadPresentations } from '@feature/presentations.slice';
import PreviewModalWindow from '@components/store/PreviewModalWindow/PreviewModalWindow';
import { toast } from 'react-toastify';

const PresentationStoreFromCollectionPage = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { patientId, collectionId } = useParams<{
        patientId: string;
        collectionId: string;
    }>();

    const [presentationTemplates, setPresentationTemplates] = useState<
        StorePresentation[]
    >([]);
    const [connectedPresentations, setConnectedPresentations] = useState<
        IConnectedToCollectionPresentation[]
    >([]);
    const [isTemplatesLoading, setIsTemplatesLoading] = useState(true);
    const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(false);
    const [announcements, setAnnouncements] = useState<IAnnouncements>();
    const [isModalShown, setIsModalShown] = useState(false);
    const [selectedPresentationTemplate, setSelectedPresentationTemplate] =
        useState<StorePresentation | null>(null);

    // Загрузка шаблонов и связанных презентаций
    useEffect(() => {
        setIsTemplatesLoading(true);

        Promise.all([
            getPresentationTemplates(),
            photoCollectionAPIs.getConnectedPresentations(collectionId),
        ])
            .then(
                ([
                    presentationTemplatesResponse,
                    connectedPresentationsResponse,
                ]) => {
                    setPresentationTemplates(
                        presentationTemplatesResponse.data
                    );
                    setConnectedPresentations(
                        connectedPresentationsResponse.data
                    );
                }
            )
            .finally(() => setIsTemplatesLoading(false));
    }, [collectionId]);

    // Загрузка слайдов для выбранного шаблона презентации
    useEffect(() => {
        if (selectedPresentationTemplate) {
            setIsAnnouncementsLoading(true);

            presentationAPIs
                .getAnnouncements(selectedPresentationTemplate.id)
                .then((res) => {
                    setAnnouncements(res.data);
                })
                .finally(() => setIsAnnouncementsLoading(false));
        }
    }, [selectedPresentationTemplate]);

    // Создание новой или открытия существующей презентации
    const handleCreateOrOpenPresentation = useCallback(
        (
            template: StorePresentation,
            connectedPresentation:
                | IConnectedToCollectionPresentation
                | undefined
        ) => {
            if (connectedPresentation) {
                history.push(
                    ROUTES.PRESENTATIONS.$(connectedPresentation.id).END
                );
            } else {
                toast.loading('Создание презентации');
                presentationAPIs
                    .createPresentation(template.id, patientId, collectionId)
                    .then((res) => {
                        dispatch(asyncLoadPresentations());
                        history.push(ROUTES.PRESENTATIONS.$(res.data.id).END);
                    })
                    .finally(() => toast.dismiss());
            }
        },
        [collectionId, dispatch, history, patientId]
    );

    // Открытие предпросмотра шаблона презентации
    const handlePreviewTemplate = useCallback((template: StorePresentation) => {
        setSelectedPresentationTemplate(template);
        setIsModalShown(true);
    }, []);

    return (
        <div className={style.page}>
            {isTemplatesLoading ? (
                <CustomLoader size={44} />
            ) : (
                <div className={style.page__container}>
                    {presentationTemplates.map((template) => {
                        const connectedPresentation =
                            connectedPresentations.find(
                                (presentation) =>
                                    presentation.key === template.key
                            );
                        return (
                            <TemplatePreview
                                key={template.id}
                                item={template}
                                buttonHandlers={[
                                    {
                                        title: connectedPresentation
                                            ? 'Открыть'
                                            : 'Создать',
                                        onClickFunction: () =>
                                            handleCreateOrOpenPresentation(
                                                template,
                                                connectedPresentation
                                            ),
                                        color: 'BLUE',
                                        padding: '7px 43px',
                                    },
                                    {
                                        title: 'Предпросмотр',
                                        color: 'WHITE',
                                        onClickFunction: () =>
                                            handlePreviewTemplate(template),
                                        padding: '7px 18px',
                                    },
                                ]}
                            />
                        );
                    })}
                </div>
            )}
            {isModalShown && (
                <PreviewModalWindow
                    setIsModalShown={setIsModalShown}
                    selectedPresentationTemplate={selectedPresentationTemplate}
                    isSliderLoading={isAnnouncementsLoading}
                    announcements={announcements?.announcements ?? []}
                />
            )}
        </div>
    );
};

export default PresentationStoreFromCollectionPage;
