import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { TrgToolEnum } from '@interfaces/tools.interface';

export interface ITrgTool {
    value: TrgToolEnum | null;
    tools: TrgToolEnum[] | null;
}

const initial_state: ITrgTool = {
    value: null,
    tools: [],
};

export const edit_tool_slice = createSlice({
    name: 'trg_edit-tool',
    initialState: initial_state,
    reducers: {
        setTrgEditTool(
            state: ITrgTool,
            action: PayloadAction<TrgToolEnum | null>
        ) {
            state.value = action.payload;
        },
        setTrgEnabledEditTool(
            state: ITrgTool,
            action: PayloadAction<TrgToolEnum[] | null>
        ) {
            state.tools = action.payload;
        },
    },
});

export const { setTrgEditTool, setTrgEnabledEditTool } =
    edit_tool_slice.actions;
export const selectTrgEditTool = (state: RootState): TrgToolEnum | null =>
    state.edit__tool.value;
export const selectTrgEnabledEditTool = (
    state: RootState
): TrgToolEnum[] | null => state.edit__tool.tools;

export default edit_tool_slice.reducer;
