import React from 'react';

export const getMeasurementSymbol = (measurement: string) => {
    switch (measurement) {
        case 'DEGREE':
            return <>&deg;</>;
        case 'PERCENT':
            return <>&#37;</>;
        case 'MM':
            return ' MM';
        default:
            return '';
    }
};
